<template>
  <section class="compose-content">
    <div v-show="!clickedItem">
      <b-tabs content-class="mt-2" v-model="tabindex">
        <b-tab :title="$t('SEARCH')">
      
          <b-form inline :key="filterKey">
            <b-button class="no-border" @click="searchAddress"
              ><i class="fal fa-sync"></i
            ></b-button>

            <FilterInput
              :title="$t('NAME')"
              v-model="filter_name"
            ></FilterInput>
            <FilterText
              :title="$t('DESCRIPTION')"
              v-model="filter_description"
            ></FilterText>

            <FilterItems
              :title="$t('FILTER.SDK.CODE')"
              v-model="filter_code"
              :filterData="filter_code_data"
            ></FilterItems>
            <FilterItems
              :title="$t('FILTER.SDK.CATEGORY')"
              v-model="filter_category"
              :filterData="filter_category_data"
            ></FilterItems>
            <FilterItems
              :title="$t('FILTER.SDK.MUNICIPALITY')"
              v-model="filter_municipality"
              :filterData="filter_municipality_data"
            ></FilterItems>
            <FilterItems
              :title="$t('FILTER.SDK.REGION')"
              v-model="filter_region"
              :filterData="filter_region_data"
            ></FilterItems>

            <FilterCustom
              :title="$t('FILTER.SDK.CUSTOM')"
              v-model="filter_custom"
            ></FilterCustom>
          </b-form>

          <b-navbar class="navbar-internal-menu mb-0 pb-0 mt-0 pt-0">
            <b-navbar-nav>
              <b-nav-item href="#" v-if="!currentFilter">{{ $t('SELECTED') }}:</b-nav-item>
              <b-nav-item href="#" v-if="currentFilter">{{ currentFilter.name }}:</b-nav-item>
              <b-nav-text class="nav-tags" :key="filter_all_data.length">
                <span class="tag" :key="key" v-for="(item, key) in filter_all_data">{{ item.text }} <i @click="removeTag(item)" class="clickable fal fa-times"></i></span>
              </b-nav-text>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item-dropdown right                
                v-if="!currentFilter"
                id="dropdown-1"
                :text="$t('MANAGE')"
                class="m-md-2 pull-right"
                toggle-class="no-border"
              >
                <b-dropdown-item @click="clearFilterAll"  v-if="hasFilter">{{
                  $t("FILTER.CLEAR")
                }}</b-dropdown-item>
                <b-dropdown-item v-b-modal.save-as-new>{{
                  $t("FILTER.SDK.SAVE_NEW")
                }}</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item-dropdown right
                v-if="currentFilter"              
                  id="dropdown-1"
                  :text="$t('MANAGE')"
                  class="m-md-2"
                  toggle-class="no-border"
                >
                  <b-dropdown-item @click="clearFilterAll">{{
                    $t("FILTER.CLEAR")
                  }}</b-dropdown-item>
                  <b-dropdown-item v-if="currentFilter" @click="UpdateFiler">{{
                    $t("SAVE")
                  }}</b-dropdown-item>
                  <b-dropdown-divider v-if="currentFilter"></b-dropdown-divider>
                  <b-dropdown-item v-b-modal.save-as-new>{{
                    $t("FILTER.SDK.SAVE_NEW")
                  }}</b-dropdown-item>
                  <b-dropdown-item v-if="currentFilter" @click="removeFilter">{{
                    $t("FILTER.SDK.REMOVE")
                  }}</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-navbar>

          <div class="mb-2"></div>

          <div v-if="items">
            <b-table
              striped
              hover
              :items="filteredAddressItems"
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
            >
            
            <template v-slot:cell(type)="data">

              <b-button :title="$t('SDK.SAVE_RECIPIENT')" v-b-tooltip.hover class="no-border" @click="saveAddress(data.item)" size="sm" v-if="!verifySavedAddress(data.item)">
                <i class="fa-sharp fa-regular fa-bookmark"></i>
              </b-button>
        
              <b-button :title="$t('SDK.REMOVE_RECIPIENT')" v-b-tooltip.hover class="no-border" @click="removeAddress(data.item)" size="sm" v-if="verifySavedAddress(data.item)">                
                <i class="fa-sharp fa-solid fa-bookmark"></i>
              </b-button>

              {{ data.item.attributes.name }}, {{ data.item.relationships.parent.data.name }}
              <br />
              {{  data.item.attributes.description }}
              <br />
              <b-button
                variant="dark"
                size="sm"
                @click="selectedAddress(data.item)"
                >{{ $t("CHOOSE") }}</b-button
              >
              <b-button
                variant="secondary"
                size="sm"
                @click="showMe(data.item)"
                >{{ $t("MORE_INFORMATION") }}</b-button
              >
        
            </template>

              <template v-slot:cell(id)="data">

                <b-button
                  variant="dark"
                  size="sm"
                  @click="selectedAddress(data.item)"
                  >{{ $t("CHOOSE") }}</b-button
                >
                <b-button
                  variant="secondary"
                  size="sm"
                  @click="showMe(data.item)"
                  >{{ $t("MORE_INFORMATION") }}</b-button
                >
            


              </template>

              
              <template v-slot:cell(attributes.name)="data">

                <b-button :title="$t('SDK.SAVE_RECIPIENT')" v-b-tooltip.hover class="no-border" @click="saveAddress(data.item)" size="sm" v-if="!verifySavedAddress(data.item)">
                  <i class="fa-sharp fa-regular fa-bookmark"></i>
                </b-button>
          
                <b-button :title="$t('SDK.REMOVE_RECIPIENT')" v-b-tooltip.hover class="no-border" @click="removeAddress(data.item)" size="sm" v-if="verifySavedAddress(data.item)">                
                  <i class="fa-sharp fa-solid fa-bookmark"></i>
                </b-button>

                {{ data.item.attributes.name }}<p class="mt-2">{{ data.item.relationships.parent.data.name }}</p>
              </template>
            </b-table>

            <b-pagination
              v-model="currentPage"
              :total-rows="filteredAddressItems.length"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </b-tab>
        <b-tab :title="$t('FILTER.SDK.SAVED_FILTERS')">
          <div :key="filter_list.length">
            <b-button class="no-border" @click="getSavedFilters"
              ><i class="fal fa-sync"></i
            ></b-button>
            <b-table
              striped
              hover
              :per-page="filterLimit"
              @row-clicked="SetFilter"
              :items="filter_list"
              :fields="fieldsFilter"
            >
              <template v-slot:cell(id)="data">
                <b-form-checkbox
                  v-model="selected"
                  :value="data.item.id"
                  name="check-button"
                ></b-form-checkbox>
              </template>
            </b-table>

            <b-row class="mb-2">
              <b-col cols="12" md="6">
                <div>
                  <b-form inline>
                    <b-button
                      variant="danger"
                      v-if="selected.length != 0"
                      @click="removeSelected"
                      >{{ $t("REMOVE_SELECTED") }}</b-button
                    >
                  </b-form>
                </div>
              </b-col>
              <b-col cols="12" md="6">

                <b-pagination
                  v-model="currentFilterPage"
                  :total-rows="filter_list.length"
                  :per-page="filterLimit"
                  aria-controls="my-table"
                ></b-pagination>

              </b-col>
            </b-row>
          </div>
        </b-tab>
      </b-tabs>
    </div>

    <div v-if="clickedItem">
      <SDKInformation
        :item="clickedItem"
        :functionbox_uuid="functionbox_uuid"
        @setItem="setItem"
        @selectedAddress="selectedAddress"
        @unselectAddress="unselectAddress"
        @backBtn="backBtn"
      ></SDKInformation>    
    </div>

    <b-modal
      id="save-as-new"
      hide-header
      @cancel="cancelSave"
      :dialog-class="teams.theme"
      @ok="okSave"
      centered
      :cancel-title="$t('CANCEL')"
      :ok-title="$t('SAVE')"
    >
      <b-form-input trim
        :placeholder="$t('NAME')"
        v-model="new_filter_name"
      ></b-form-input>
    </b-modal>
    
  </section>
</template>
<script>
import SDKInformation from "../../Input/SDK/SdkInformation";
import FilterItems from "../../Input/Filter/FilterItems";
import FilterText from "../../Input/Filter/FilterText";
import FilterCustom from "../../Input/Filter/FilterCustom";
import FilterInput from "../../Input/Filter/FilterInput";
export default {
  components: {
    SDKInformation,
    FilterItems,
    FilterText,
    FilterInput,
    FilterCustom
  },
  props: ["functionbox_uuid"],
  data() {
    return {
      fieldsFilter: [
        {
          key: "id",
          label: "",
          class: "text-break w-icon clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "name",
          label: "Namn",
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
      ],
      filterIndex: 0,
      filterLimit: 5,
      currentFilterPage: 1,
      tabindex: 0,
      selected: [],
      currentFilter: null,
      filter_list: [],
      savedFilters: false,
      filter_name: "",
      new_filter_name: "",
      filter_description: "",
      filter_code: [],
      filter_code_data: [],
      filter_region: [],
      filter_region_data: [],
      filter_municipality: [],
      filter_municipality_data: [],
      filter_custom: [],
      filter_category: [],
      filter_category_data: [],
      filter_data: null,
      filter_all_data: [],
      currentPage: 1,
      perPage: 5,
      showAll: false,
      clickedItem: null,
      loading: false,
      searchText: "",
      searchData: null,
      items: [],
      saved_addresses: [],
      fields: [
        {
          key: "attributes.name",
          label: this.$t("NAME"),
          class: "hidden-mobile",
          thClass: "w-300",
        },
        {
          key: "attributes.description",
          label:  this.$t("DESCRIPTION"),
          class: "hidden-mobile",
          thClass: "w-350",
        },
        {
          key: "id",
          label: "",
          class: "text-break clickable hidden-mobile",
          thClass: "w-300",
        },
        {
          key: "type",
          label: "",
          class: "text-break clickable hidden-not-mobile",
          thClass: "w-300",
        }
      ],
    };
  },
  methods: {
    setItem(item){
      this.clickedItem = item;
    },
    async backBtn(){
      await this.GetSavedAddresses();
      this.clickedItem = null;
    },
    async UpdateFiler() {
      let self = this;
      self.loading = true;
      let AddSdkSefosFilterDTO = {
        id: this.currentFilter.id,
        name: this.currentFilter.name,
        functionbox_uuid: this.functionbox_uuid,
        filter_settings: {
          name: this.filter_name,
          description: this.filter_description,
          code: this.filter_code,
          category: this.filter_category,
          municipality: this.filter_municipality,
          region: this.filter_region,
          custom: this.filter_custom,
        },
      };
      await this.$http
        .post(
          this.user.hostname + "/sdk/filter/update",
          AddSdkSefosFilterDTO
        )
        .then(function() {
          self.$noty.info(self.$t("SAVED"));
          self.getSavedFilters();
          self.loading = false;
        })
        .catch(function() {
          self.loading = false;
        });
    },
    selectedAddress(item) {
      this.$emit('selectedAddress', item);
    },
    filterSearchCodes() {
      ////console.log(item);
    },
    SetFilter(item) {
      this.currentFilter = item;
      this.filter_category = item.filter_settings.category;
      this.filter_code = item.filter_settings.code;
      this.filter_custom = item.filter_settings.custom;
      this.filter_description = item.filter_settings.description;
      this.filter_municipality = item.filter_settings.municipality;
      this.filter_name = item.filter_settings.name;
      this.filter_region = item.filter_settings.region;
      this.tabindex = 0;
      this.filterIndex++;
    },
    cancelSave() {
      this.new_filter_name = "";
    },
    async okSave() {
      let self = this;
      self.loading = true;
      let AddSdkSefosFilterDTO = {
        name: this.new_filter_name,
        functionbox_uuid: this.functionbox_uuid,
        filter_settings: {
          name: this.filter_name,
          description: this.filter_description,
          code: this.filter_code,
          category: this.filter_category,
          municipality: this.filter_municipality,
          region: this.filter_region,
          custom: this.filter_custom,
        },
      };
      await this.$http
        .post(
          this.user.hostname + "/sdk/filter/add",
          AddSdkSefosFilterDTO
        )
        .then(function(result) {
          self.$noty.info(self.$t("SAVED"));
          self.new_filter_name = "";
          self.getSavedFilters();
          self.SetFilter(result.data);
          self.loading = false;
        })
        .catch(function() {
          self.loading = false;
        });
    },
    removeFilter() {
      let self = this;
      self.loading = true;
      let id_list = [];
      id_list.push(this.currentFilter.id);
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async function(value) {
          if (value) {
            let RemoveSdkSefosFilterDTO = {
              id_list: id_list,
              functionbox_uuid: self.functionbox_uuid,
            };
            await self.$http
              .post(
                self.user.hostname + "/sdk/filter/remove",
                RemoveSdkSefosFilterDTO
              )
              .then(function() {
                self.$noty.info(self.$t("REMOVED"));
                self.getSavedFilters();
                self.currentFilter = null;
                self.clearFilterAll();
                self.loading = false;
              })
              .catch(function() {
                self.loading = false;
              });
          }
        })
        .catch(function() {});
    },
    async removeSelected() {
      let self = this;
      self.loading = true;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async function(value) {
          if (value) {
            let RemoveSdkSefosFilterDTO = {
              id_list: self.selected,
              functionbox_uuid: self.functionbox_uuid,
            };
            await self.$http
              .post(
                self.user.hostname + "/sdk/filter/remove",
                RemoveSdkSefosFilterDTO
              )
              .then(function() {
                self.$noty.info(self.$t("REMOVED"));
                self.getSavedFilters();
                self.loading = false;
              })
              .catch(function() {
                self.loading = false;
              });
          }
        })
        .catch(function() {});
    },
    getItems() {},
    cancelBtn() {
      this.$emit("cancel");
    },
    async unselectAddress() {
      await this.GetSavedAddresses();
      this.clickedItem = null;
    },
    showMe(item) {
      this.clickedItem = item;
    },
    async getFilterData() {
      let self = this;
      this.loading = true;
      await this.$http
        .post(this.user.hostname + "/sdk/filter-data", {
          functionbox_uuid: this.functionbox_uuid,
        })
        .then(function(result) {
          self.populateFilterData(result.data);
          self.loading = false;
        })
        .catch(function() {
          self.loading = false;
        });
    },
    async searchAddress() {
      let self = this;
      let SdkSefosFilterDTO = {
        id: 0,
        name: "",
        functionbox_uuid: this.functionbox_uuid,
        filter_settings: {
          name: this.filter_name,
          description: this.filter_description,
          code: this.filter_code,
          category: this.filter_category,
          municipality: this.filter_municipality,
          region: this.filter_region,
          custom: this.filter_custom,
        },
      };
      self.loading = true;
      await this.$http
        .post(this.user.hostname + "/sdk/address/search", SdkSefosFilterDTO)
        .then(function(result) {
          self.items = result.data;
          self.loading = false;
          self.UpdateFilterAllData();
        })
        .catch(function() {
          self.loading = false;
          self.UpdateFilterAllData();
        });
    },
    async GetSavedAddresses() {
      try {
        let result = await this.$http.get(this.user.hostname + "/sdk/address/" + this.functionbox_uuid);
        this.saved_addresses = result.data;
      } catch { 
        this.saved_addresses = [];
      }
    },
    verifySavedAddress(adress) {
      let result = this.saved_addresses.filter(function(item) {
        return (item.sdk_identifier == adress.attributes.identifier && item.sdk_organisation == adress.relationships.parent.data.identifier);
      });
      return result.length > 0;
    },
    async saveAddress(item) {
      let AddSdkSefosAddressDTO = {
        functionbox_uuid: this.functionbox_uuid,
        sdk_organisation: item.relationships.parent.data.identifier,
        sdk_identifier: item.attributes.identifier,
      };
      await this.$http.post(this.user.hostname + "/sdk/address/add", AddSdkSefosAddressDTO);
      this.GetSavedAddresses();
    },
    async removeAddress(adress) {
      let self = this;
      self.loading = true;
      let id_list = [];
      let result = this.saved_addresses.filter(function(item) {
        return (item.sdk_identifier == adress.attributes.identifier && item.sdk_organisation == adress.relationships.parent.data.identifier);
      });
      id_list.push(result[0].id);
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async function (value) {
          if (value) {
            let RemoveSdkSefosAddressDTO = {
              id_list: id_list,
              functionbox_uuid: self.functionbox_uuid,
            };
            await self.$http.post(self.user.hostname + "/sdk/address/remove",RemoveSdkSefosAddressDTO);
            //self.$noty.info(self.$t("REMOVED"));
            self.GetSavedAddresses();              
          }
        })
        .catch(function () {});
    },
    async getSavedFilters() {
      let self = this;
      self.loading = true;
      await this.$http
        .get(
          this.user.hostname + "/sdk/filter/" + this.functionbox_uuid
        )
        .then(function(result) {
          self.filter_list = result.data;
          self.loading = false;
        })
        .catch(function() {
          self.loading = false;
        });
    },
    clearFilterAll() {
      this.currentFilter = null;
      this.filterIndex++;
      this.filter_name = "";
      this.filter_description = "";
      this.filter_code = [];
      this.filter_category = [];
      this.filter_region = [];
      this.filter_municipality = [];
      this.filter_custom = [];
    },
    populateFilterData(data) {
      this.filter_data = data;
      this.filter_code_data = this.filter_data.codes.map(function(item) {
        return {
          type: 'code',        
          text: item.coding.displayText,
          id: item.coding.code,
          description: item.coding.additionalInfo,
        };
      }); 
      this.filter_category_data = this.filter_data.categories.map(function(
        item
      ) {
        return {
          type: 'category',        
          text: item, 
          id: item,
          description: "" };
      });
      this.filter_region_data = this.filter_data.regionCode.map(
        function(item) {
          return {
            type: 'region',
            text: item.coding.displayText,
            id: item.coding.code,
            description: item.coding.additionalInfo,
          };
        }
      );
      this.filter_municipality_data = this.filter_data.municipalityCode.map(
        function(item) {
          return {
            type: 'municipality',
            text: item.coding.displayText,
            id: item.coding.code,
            description: item.coding.additionalInfo,
          };
        }
      );
    },    
    removeTag(item)
    {
      if(item.type == 'name')
      {
        this.filter_name = '';
      }
      if(item.type == 'description')
      {
        this.filter_description = '';
      }
      if(item.type == 'code')
      {
        this.filter_code = this.filter_code.filter(function(e) { return e !== item.id });
        this.filterIndex++;
      }
      if(item.type == 'category')
      {
        this.filter_category = this.filter_category.filter(function(e) { return e !== item.id });
        this.filterIndex++;
      }
      if(item.type == 'municipality')
      {
        this.filter_municipality = this.filter_municipality.filter(function(e) { return e !== item.id });
        this.filterIndex++;
      }
      if(item.type == 'region')
      {
        this.filter_region = this.filter_region.filter(function(e) { return e !== item.id });
        this.filterIndex++;
      }
      if(item.type == 'custom')
      {
        this.filter_custom.splice(item.index,1);// = this.filter_custom.filter(function(e) { return e !== item.text });
        this.filterIndex++;
      }
    },
    UpdateFilterAllData(){
      this.filter_all_data = [];
      if(this.filter_name != "")
      {
        this.filter_all_data.push({
            type: 'name',
            text: this.filter_name,
            id: 0,
            description: '',
          });
      }
      if(this.filter_description != "")
      {
        this.filter_all_data.push({
            type: 'description',
            text: this.filter_description,
            id: 0,
            description: '',
          });
      }
      for(let ix = 0; ix < this.filter_code_data.length; ix++)
      {
        let item = this.filter_code_data[ix];
        if(this.filter_code.includes(item.id) )
        {          
          this.filter_all_data.push(item);
        }
      }      
      for(let ix = 0; ix < this.filter_category_data.length; ix++)
      {
        let item = this.filter_category_data[ix];
        if(this.filter_category.includes(item.id) )
        {          
          this.filter_all_data.push(item);
        }
      }      
      for(let ix = 0; ix < this.filter_municipality_data.length; ix++)
      {
        let item = this.filter_municipality_data[ix];
        if(this.filter_municipality.includes(item.id) )
        {          
          this.filter_all_data.push(item);
        }
      }      
      for(let ix = 0; ix < this.filter_region_data.length; ix++)
      {
        let item = this.filter_region_data[ix];
        if(this.filter_region.includes(item.id) )
        {          
          this.filter_all_data.push(item);
        }
      }      
      for(let ix = 0; ix < this.filter_custom.length; ix++)
      {
        let item = this.filter_custom[ix];
        this.filter_all_data.push({
            type: 'custom',
            text: item,
            id: ix,
            description: item,
          });
      }      
    },
  },
  computed: {
    filteredAddressItems(){
      return this.items;
      /*return this.items.filter(function(item) {
          return item.attributes.identifier != "sdk.otm.0203:test.secureappbox.com";
        });*/
    },
    filterKey() {
      return "filterKey" + this.filterIndex;
    },
    hasFilter(){
      if(this.filter_name != ""){ return true;}
      if(this.filter_description != ""){ return true;}
      if(this.filter_code.length != 0){ return true;}
      if(this.filter_category.length != 0){ return true;}
      if(this.filter_region.length != 0){ return true;}
      if(this.filter_municipality.length != 0){ return true;}
      if(this.filter_custom.length != 0){ return true;}
      return false;
    }
  },
  watch: {
    filter_name: function() {
      this.searchAddress();
    },
    filter_description: function() {
      this.searchAddress();
    },
    filter_code: function() {
      this.searchAddress();
    },
    filter_municipality: function() {
      this.searchAddress();
    },
    filter_category: function() {
      this.searchAddress();
    },
    filter_region: function() {
      this.searchAddress();
    },
    filter_custom: function() {
      this.searchAddress();
    },
  },
  mounted() {
    this.getSavedFilters();
    this.getFilterData();
    this.searchAddress();
    this.GetSavedAddresses();
  },
};
</script>
<style></style>
