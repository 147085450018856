var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "b-overlay",
        {
          key: "AddFiles" + _vm.message_uuid,
          attrs: { show: _vm.disableUpload, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c("div", { staticClass: "text-center" }, [
                    !_vm.totalSize
                      ? _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("ATTACHMENTS_PER_SIZE_LIMIT", {
                                  filesize: _vm.maxSize + "",
                                })
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm.totalSize
                      ? _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("ATTACHMENTS_SIZE_LIMIT", {
                                  filesize: _vm.maxSize + "",
                                })
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.message_uuid
            ? _c(
                "b-button",
                {
                  staticClass: "no-border",
                  attrs: { variant: "primary" },
                  on: { click: _vm.openLocalStorage },
                },
                [_vm._v(_vm._s(_vm.$t("THIS_COMPUTER")))]
              )
            : _vm._e(),
          _vm.filestorage_enabled && _vm.message_uuid
            ? _c(
                "b-button",
                {
                  staticClass: "no-border",
                  attrs: { variant: "primary" },
                  on: { click: _vm.openFileStorage },
                },
                [_vm._v(_vm._s(_vm.$t("FILESTORAGE.TITLE")))]
              )
            : _vm._e(),
        ],
        1
      ),
      !_vm.message_uuid && _vm.cannotUpload
        ? _c(
            "b-alert",
            { staticClass: "mb-2", attrs: { show: "", variant: "info" } },
            [_vm._v(_vm._s(_vm.cannotUpload))]
          )
        : _vm._e(),
      _vm.showTitle
        ? _c("div", { staticClass: "info-header mt-2" }, [
            _vm._v(_vm._s(_vm.$t("ATTACHMENTS"))),
          ])
        : _vm._e(),
      _vm.attachments.length != 0
        ? _c(
            "span",
            { key: "attachments_" + _vm.attachments.length },
            _vm._l(_vm.attachments, function (item, index) {
              return _c(
                "span",
                {
                  key: index,
                  staticClass: "ms-fontSize-12 mt-2 compose-attachment",
                },
                [
                  _c("Attachment", {
                    attrs: {
                      "is-uploaded": "",
                      "show-download": item.attachment_uuid != "",
                      item: item,
                      index: index,
                    },
                    on: {
                      RemoveAttachment: _vm.RemoveAttachment,
                      DownloadAttachment: _vm.DownloadAttachment,
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm.files.length != 0
        ? _c(
            "span",
            { key: "localattachments_showing_" + _vm.files.length },
            _vm._l(_vm.files, function (item, index) {
              return _c(
                "span",
                { key: index, staticClass: "ms-fontSize-12 mt-2" },
                [
                  _c(
                    "span",
                    { class: _vm.filesClass(item) },
                    [
                      _c("Attachment", {
                        attrs: {
                          "show-download": _vm.message_uuid != "",
                          percentage: _vm.GetPercentage(item),
                          item: item,
                          "is-error": _vm.HasError(item),
                          index: index,
                        },
                        on: { RemoveAttachment: _vm.RemoveFile },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm.attachments.length == 0
        ? _c("b-form-text", { attrs: { id: "NO_FILES_SELECTED" } }, [
            _vm._v("\n      " + _vm._s(_vm.$t("NO_FILES_SELECTED")) + "\n    "),
          ])
        : _vm._e(),
      !_vm.AllValidFilesize
        ? _c("div", { staticClass: "error-text" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("ERROR.LARGE_FILES")) +
                " " +
                _vm._s(this.maxSize + "MB") +
                "\n    "
            ),
          ])
        : _vm._e(),
      !_vm.AllValidNames
        ? _c("div", { staticClass: "error-text" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("ERROR.TOO_MANY_CHARACTERS")) +
                " " +
                _vm._s(256) +
                "\n    "
            ),
          ])
        : _vm._e(),
      !_vm.allValidText
        ? _c("div", { staticClass: "error-text" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("ERROR.CHARACTERS_NOT_ALLOWED")) +
                " " +
                _vm._s(_vm.notAllowedCharacters) +
                "\n    "
            ),
          ])
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            "dialog-class": _vm.teams.theme,
            size: "lg",
            "hide-header": "",
            "no-close-on-backdrop": "",
            visible: "",
            "ok-variant": "secondary",
            centered: "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel, hide }) {
                return [
                  _vm.disableUpload
                    ? _c("div", [
                        !_vm.totalSize
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("ATTACHMENTS_PER_SIZE_LIMIT", {
                                      filesize: _vm.maxSize + "",
                                    })
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm.totalSize
                          ? _c("div", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("ATTACHMENTS_SIZE_LIMIT", {
                                      filesize: _vm.maxSize + "",
                                    })
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  !_vm.disableUpload
                    ? _c("b-button", { on: { click: _vm.closeDialog } }, [
                        _vm._v(_vm._s(_vm.$t("CLOSE"))),
                      ])
                    : _vm._e(),
                ]
              },
            },
          ]),
          model: {
            value: _vm.openDialog,
            callback: function ($$v) {
              _vm.openDialog = $$v
            },
            expression: "openDialog",
          },
        },
        [
          _vm.showWarning
            ? _c(
                "b-alert",
                {
                  staticClass: "text-center mt-2 pl-1 pt-1 pb-1 pr-1",
                  attrs: { show: "", variant: "warning" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("MEETING_ATTACH_NEW")) +
                      "\n      "
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.fileLocation == 0,
                  expression: "fileLocation == 0",
                },
              ],
            },
            [
              _c("FileSelect", {
                key: "localFiles_" + _vm.localFiles.length,
                staticClass: "mt-2",
                attrs: {
                  "show-drop": "",
                  fileType: _vm.fileType,
                  "total-size": _vm.totalSize,
                  "max-size": _vm.maxSize,
                },
                on: { added: _vm.UpdatedFiles },
                model: {
                  value: _vm.localFiles,
                  callback: function ($$v) {
                    _vm.localFiles = $$v
                  },
                  expression: "localFiles",
                },
              }),
              _vm.files.length != 0
                ? _c(
                    "span",
                    { key: "localattachments_" + _vm.files.length },
                    _vm._l(_vm.files, function (item, index) {
                      return _c(
                        "span",
                        { key: index, staticClass: "ms-fontSize-12 mt-2" },
                        [
                          _c(
                            "span",
                            { class: _vm.filesClass(item) },
                            [
                              _c("Attachment", {
                                attrs: {
                                  "show-download": _vm.message_uuid != "",
                                  percentage: _vm.GetPercentage(item),
                                  item: item,
                                  "is-error": _vm.HasError(item),
                                  index: index,
                                },
                                on: { RemoveAttachment: _vm.RemoveFile },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.fileLocation == 1,
                  expression: "fileLocation == 1",
                },
              ],
            },
            [
              _c("SelectFiles", {
                attrs: { filter: _vm.attachments, fileType: _vm.fileType },
                on: { selected: _vm.selecteFilestorageFile },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }